import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import {useEffect, useState} from "react";
import React from 'react';
import authHeader from "../utils/auth-header";
import toast, {Toaster} from "react-hot-toast";
import LoadingSpinner from "../components/loading-spinner";
import {graphql, navigate} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";

const ResetPassword = ({ location }) => {

    const {t} = useTranslation();
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {


        const params = new URLSearchParams(location.search);
        const emailparam = params.get("email");
        const tokenparam = params.get("token");

        setEmail(emailparam);
        setToken(tokenparam);


    }, [])


    const handleChangePassword = (e) => {

        e.preventDefault();
        setLoading(true)

        try {

            setSubmitDisabled(true);
            setLoading(true);

            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    password: password,
                    token: token,
                    email: email,

                })
            };



            fetch(process.env.WASHCAR_API_URL + "/reset-password", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {

                    setSubmitDisabled(false);
                    setLoading(false)



                    if(resultData.status === 'Error'){
                        toast.error('No correct data')
                    }


                    if(  resultData.status === 'Success'){
                        toast.success(resultData.alert.text);
                        setTimeout(function(){
                            //window.location.href = process.env.BASE_URL;
                            return navigate('/');
                        }, 5000);

                    }


                }) // set data for the number of stars




        } catch (err) {
            console.log(err);
        }


    };


    return (
        <Layout>
            <Seo title={t('password_reset')}/>
            <h1 className="title">{t('password_reset')}</h1>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            {loading ? <div className="pos-login"><LoadingSpinner /></div> : null}

                <form onSubmit={handleChangePassword} className={'box'}>

                    <div className="field">
                        <label className="label">{t('new_password')}</label>
                        <div className="control">
                            <input required className="input" type="password" value={password}
                                   onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                    </div>

                    <div className="field is-grouped">
                        <div className="control">
                            <button disabled={submitDisabled} type="submit" className="button is-link">{t('set_new_password')}
                            </button>
                        </div>
                    </div>

                </form>

        </Layout>
    )
}

export default ResetPassword

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;